.MuiListItemIcon-root > .MuiSvgIcon-root {
  fill: var(--iconColor, white) !important;
}
.MuiSvgIcon-root {
  fill: var(--iconColor, white) !important;
}
.MuiListItemText-root {
  color: var(--iconColor, white);
}
.selected.MuiListItemIcon-root > div > div > .MuiSvgIcon-root {
  fill: var(--iconButtonSelected) !important;
}

.navItem {
  width: "100%";
  max-width: 360px;
}
.navNested {
  padding-left: 30px;
}

.MuiTypography-root {
  font-family: var(--lis-title) !important;
}

main {
  padding-top: 64px;
}

.logoHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 110px;
}

.headerTitle {
  font-size: 20px;
  width: 100%;
  text-align: center;
  font-family: var(--lis-title);
}

.selectedItem {
  border-right: 2px solid var(--secondary-color) !important;
  border-bottom: 2px solid var(--secondary-color) !important;
}
